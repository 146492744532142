<template>
  <div class="join-tele2">
    <div class="container">
      <div class="join-tele2__top">
          <h2 class="join-tele2__title">611</h2>
          <p class="join-tele2__subtitle">Для номеров t2</p>
          <h2 class="join-tele2__phone">
            <a href="tel:+74959797611">+7 495 979-76-11</a>
          </h2>
          <p class="join-tele2__subtitle">Для звонков с городских телефонов</p>
      </div>
      <div class="join-tele2__bottom">
        <div class="join-tele2__left">
          С 4 апреля 2013 года Теле2 Россия Интернешнл Селлулар БВ и её аффилированные лица не принадлежат или не являются аффилированными с Теле2 АБ и любой её дочерней компанией. Товарный знак t2 используется на основании лицензии от Теле2 АБ или её дочерней компании.
          <br><br>
        </div>
        <div class="join-tele2__right">
          Продолжая использовать наш сайт, вы даёте согласие на обработку файлов cookie в соответствии с Политикой Компаний Группы t2 в области использования файлов cookie, а также соглашаетесь с Правилами пользования личным кабинетом. | Оплата и доставка.
        </div>
      </div>
      <!-- <div class="col-sm-6">
        <div class="col-sm-6">
          <h1>Как стать нашим абонентом</h1>
          <span class="copy desktop">© Tele2 Россия, 2023</span>
        </div>
        <div class="col-sm-6 image-and-text-block">
          <div class="image-and-text-container">
            <img
              alt=""
              src="@/assets/img/icons/asset.svg"
              class="hidden-xs"
              height="51"
            />
            <div>
              <div>
                <p
                  v-html="
                    `Подключитесь к&nbsp;Tele2&nbsp;с новым номером или&nbsp;перенесите свой.<br />Это займет не&nbsp;более 5&nbsp;минут.`
                  "
                ></p>
              </div>
            </div>
          </div>
        </div>
      </div> -->
      <!-- <div class="col-sm-6">
        <div class="join-links-container">
          <a
            target="_blank"
            rel="noopener noreferrer"
            :href="`https://tele2.ru/shop/number?utm_source=web&utm_medium=site&utm_campaign=${projectName}`"
            ><div class="join-link first">
              <div class="link-container">
                <span>Выбрать новый номер</span
                ><span class="icon-right-arrow"></span>
              </div></div></a
          ><a
            target="_blank"
            rel="noopener noreferrer"
            href="https://ekt.tele2.ru/connectTariff"
            ><div class="join-link last">
              <div class="link-container">
                <span>Перейти со своим</span
                ><span class="icon-right-arrow"></span>
              </div></div
          ></a>
        </div>
      </div> -->
      <p class="copy mobile">18+ © t2 Россия, 2024</p>
    </div>
  </div>
</template>

<script>
export default {
  name: "Join",
  props: {
    projectName: {
      type: String,
      required: true,
    },
  },
};
</script>

<style scoped lang="scss">
.join-tele2 {
  position: relative;
  background-color: #000;
  color: #fff;
  padding: 56px 0 0 0;
}

.join-tele2 * {
  color: #fff;
}

.join-tele2 .col-sm-6:first-child {
  padding-left: 0;
}

.join-tele2 .join-link .link-container {
  position: relative;
  top: 50%;
  transform: translateY(-50%);
}

.join-tele2 .join-link .icon-right-arrow,
.join-tele2 .join-link .lines-new-program__abonent_action:before {
  position: absolute;
  right: 0;
  top: 3px;
}

.join-tele2.with-bottom-margins {
  margin-bottom: 54px;
}

.copy {
  font-family: var(--fontPrimary);
  font-size: 10px;
  font-weight: 500;
  line-height: 12px;
  color: #808693;
  margin-bottom: 0px;
  @include tablet {
    // text-align: center;
  }
}

.desktop {
  @include tablet {
    display: none;
  }
}

.mobile {
  // display: none;
  padding-left: 16px;
  padding-top: 12px;
  @include onlyMobile {
    // display: block;
    padding-left: 4px;
  }
}

@media (max-width: 767px) {
  .join-tele2.with-bottom-margins {
    margin-bottom: 36px;
  }

  .join-tele2 .container {
    padding-top: 30px;
    padding-bottom: 30px;
  }

  .join-tele2 .col-sm-6 {
    padding: 0;
  }

  .join-tele2 .image-and-text-block {
    margin-bottom: 30px;
  }

  .join-tele2 .join-link {
    height: 65px;
    font-weight: 700;
    border-top: 1px solid hsla(0, 0%, 100%, 0.3);
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
  }

  .join-tele2 .join-link.first {
    border-bottom: none;
  }

  .join-tele2 .join-link.last {
    border-bottom: none;
  }
}

@media (min-width: 768px) {
  .join-tele2 {
    height: 282px;
  }

  .join-tele2 .container {
    // position: relative;
    // top: 50%;
    // transform: translateY(-50%);
    // display: flex;
  }

  .join-tele2 .image-and-text-block {
    border-left: 1px solid hsla(0, 0%, 100%, 0.7);
    padding-left: 45px;
    padding-right: 0;
    height: 166px;
  }

  .join-tele2 .image-and-text-block .image-and-text-container {
    position: relative;
    top: 50%;
    transform: translateY(-50%);
  }

  .join-tele2 .image-and-text-block .image-and-text-container img {
    position: relative;
    bottom: 10px;
  }

  .join-tele2 .join-link {
    position: relative;
    height: 83px;
    font-size: 22px;
    font-weight: 700;
  }

  .join-tele2 .join-link.first {
    border-bottom: 1px solid hsla(0, 0%, 100%, 0.3);
  }

  .join-tele2 .join-link .icon-right-arrow,
  .join-tele2 .join-link .lines-new-program__abonent_action:before {
    font-weight: 700;
  }
}

.join-tele2 {
  @include onlyMobile  {
    padding: 36px 0 0 0;
  }
  &__top {
    padding: 0 16px 0;
    margin: 0px 0 30px 0;
    @include onlyMobile {
      padding: 0 4px 0;
    }
  }
  &__title {
    font-family: var(--fontSecondary);
    font-size: 18px;
    font-weight: 800;
    line-height: 16px;
    margin: 0 0 4px 0;
  }
  &__subtitle {
    font-family: var(--fontPrimary);
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    color: #6B717F;
    margin: 0px 0 24px 0;
  }
  &__phone {
    font-family: var(--fontSecondary);
    font-size: 18px;
    font-weight: 800;
    line-height: 16px;
    margin: 0px 0 4px 0;
  }
  &__bottom {
    display: flex;
    align-content: flex-start;
    justify-content: space-between;
    gap: 20px;
    padding: 0 16px 0;
    @include onlyMobile {
      padding: 0 4px 0;
      flex-direction: column;
      gap: 16px;
    }
  }
  &__left, &__right {
    width: 50%;
    font-family: var(--fontPrimary);
    font-size: 10px;
    font-weight: 500;
    line-height: 12px;
    color: #808693;
    @include onlyMobile {
      width: 100%;
    }
  }
  &__left br {
    display: none;
  }
}
</style>
